import React from 'react';
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import Me from "../assets/Me.jpg";
import "../styles/home.css";

const Home = () => {
  return (
    <div className='home'>
      <div className='about'>
        <h2>Hi, My Name is Madushi Ranasooriya</h2>
        <img src={Me} alt='Me' />
        <div className='prompt'>
          <p>A software developer with a passion for learning and coding.</p>
          <a href="https://www.linkedin.com/in/madushi-ranasooriya-854892224" target="_blank" rel="noopener noreferrer">
            <LinkedInIcon />
          </a>
          <a href="mailto:smadu1996@gmail.com">
            <EmailIcon />
          </a>
        </div>
      </div>
      <div className='skills'>
        <h1>Skills</h1>
        <ol className='list'>
          <li className='item'>
            <h2>Programming Languages</h2>
            <span>Python, JavaScript, TypeScript, Java, and C</span>
          </li>
          <li className='item'>
            <h2>Web Technologies</h2>
            <span>HTML5, CSS3, NodeJS, React, Bootstrap, and PHP</span>
          </li>
          <li className='item'>
            <h2>Databases</h2>
            <span>MySQL and Firebase (Real-time database)</span>
          </li>
          <li className='item'>
            <h2>Tools</h2>
            <span>Visual Studio Code, NetBeans, phpMyAdmin, InteliJ, and Eclipse</span>
          </li>
          <li className='item'>
            <h2>Other</h2>
            <span>Git and Matlab</span>
          </li>
        </ol>
      </div>
    </div>
  )
}

export default Home

