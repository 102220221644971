import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ReorderIcon from '@material-ui/icons/Reorder';
import "../styles/navbar.css";

const Navbar = () => {
    const [expandNavbar, setExpandNavbar] = useState(false);

    const location = useLocation();

    useEffect(() => {
        setExpandNavbar(false);
    }, [location])
    return (
        <div className='navbar' id={expandNavbar ? "open" : "close"}>
        <div className='toggleButton'>
            <button onClick={() => {setExpandNavbar((prev) => !prev)}}>
                <ReorderIcon />
            </button>
        </div>
        <div className='links'>
            <Link to='/' className={location.pathname === '/' ? 'active' : ''}>
                Home
            </Link>
            <Link to='/projects' className={location.pathname.startsWith('/project') ? 'active' : ''}>
                Projects
            </Link>
            <Link to='/experience' className={location.pathname === '/experience' ? 'active' : ''}>
                Experience
            </Link>
        </div>
        </div>
    )
}

export default Navbar;
