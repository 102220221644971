import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectList } from '../helpers/ProjectList';
import LaunchIcon from '@material-ui/icons/Launch';
import "../styles/ProjectDisplay.css";

const ProjectDisplay = () => {
    const {id} = useParams();
    const project = ProjectList[id];

    useEffect(() => {
      // Scroll to the top of the page when the component mounts
      window.scrollTo(0, 0);
    }, []);

    const getSentences = (description) => {
      return description.split('.').filter(sentence => sentence.trim() !== '');
    };
    
  return (
    <div className='project'>
      <h1>{project.name}</h1>
      <img src={project.image} alt='My Projects'/>
      {project.skills ? 
        <p>
          <b>Skills:</b> {project.skills}
        </p>
        :null
      }
      {project.link ? 
        <a href={project.link} target="_blank" rel="noopener noreferrer" className='expand'>
          <LaunchIcon style={{ fontSize: 35 }} />
        </a> 
        : null
      }
      <div className='description'>
        <b>Description:</b>
        <ul>
          {getSentences(project.description).map((sentence, index) => (
            <li key={index}>{sentence.trim()}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ProjectDisplay
