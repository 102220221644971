import React from 'react';
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import "../styles/footer.css";

const Footer = () => {
  return (
    <div className='footer'>
      <div className='socialMedia'>
          <a href="mailto:smadu1996@gmail.com">
            <EmailIcon />
          </a>
          <a href="https://www.facebook.com/madushi.ranasooriya/" target="_blank" rel="noopener noreferrer">
            <FacebookIcon />
          </a>
          <a href="https://www.linkedin.com/in/madushi-ranasooriya-854892224" target="_blank" rel="noopener noreferrer">
            <LinkedInIcon />
          </a>
      </div>
      <p>&copy; 2023 madushiranasooriyaportfolio.com</p>
    </div>
  )
}

export default Footer
